body {
  color: rgb(77, 73, 70);
}

.title {
  background-image: url("leaf.png");
  background-color: transparent;
  background-position: center top, left top;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  text-align: center;
  max-height: 400px;
  margin-bottom: 50px;
}

.name-container {
  padding-top: 175px;
  padding-bottom: 75px;
}

@media screen and (min-width: 1850px) {
  .title {
    background-size: contain;
  }
}

@media screen and (max-width: 950px) {
  .name-container {
    padding-top: 150px;
    padding-bottom: 20px;
  }
}

.name-title {
  font-size: 52.8px;
  font-family: "Baskerville Book BQ Regular";
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.name-and { 
  font-size: 40px;
  font-family: 'Allura', cursive;

  font-weight: 400;
  font-style: normal;
}

.couple {
  display: flex;
  justify-content: center;
}

.couple-img { 
  width: 90%;
  max-width: 900px;
  box-sizing: border-box;
  border: 8px solid rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 4px;
  margin-bottom: 50px;
}

.join-container {
  color: rgb(77, 73, 70);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Baskerville Book BQ Regular";

}

.font-20 {
  font-size: 20px;
}

.font-40 {
  font-size: 40px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.join {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-family: 'Allura', cursive;
}

.date {
  margin-bottom: 5px;
}

.location {
  font-family: 'Allura', cursive;
}

.reg-title {
  font-family: 'Allura', cursive;
  margin-bottom: 20px;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
}

.info {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  word-break: break-word;
}

.info > div {
  padding-left: 20px;
  padding-right: 20px;
}

.amazon {
  margin-right: 50px;
  margin-bottom: 0px;
}

.bbb {
  margin-left: 50px;
}

.w-33 {
  width: 33%;
}

.w-50 {
  width: 50%;
}

a{
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .links {
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  .amazon {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .bbb {
    margin-left: 0px;
  }
  .info {
    display: flex;
    flex-direction: column;
  }
}

.links img {
  width: 200px;
}

@media screen and (max-width: 1200px) {
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info > .w-50 {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 790px) {
  .w-50 {
    width: 80%;
  }
}

#clockdiv {
  font-family: sans-serif;
  color: #fff;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
  margin-top: 50px;
}

#clockdiv > div {
  padding: 10px;
  background: rgb(148,159,101, .7);
  display: inline-block;
}

#clockdiv div > span {
  padding: 15px;
  border-radius: 3px;
  background: rgb(148,159,101);
  display: inline-block;
}

.smalltext {
  padding-top: 5px;
  font-size: 16px;
}
